import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        At our core, we're like tech-savvy adventurers, hunting for hidden digital treasures – the kind that tech, gaming, and computer aficionados crave. Once we spot these gems, we're on a fast track to bring them right to your fingertips.
      </Text>
    </Card>
  </Section>
)

export default Commitment
